<template>
  <div>
        <div class="main" v-title data-title="百川资管-加入百川">
    </div>
    <Header class="header" active="join"/>
    <div class="banner">
      <div class="background">
        <b-container>
          <div class="title">纵然不确定，但并非不可预知</div>
          <div class="title">期待志在金融与科技融合的你</div>
          <div class="title">加入百川的团队，共赴大海星辰</div>
          <a href="javascript:">查看所有职位</a>
        </b-container>
      </div>
    </div>
    <div class="content">
      <section class="culture">
        <b-container>
          <b-row>
            <div class="text">百川文化</div>
          </b-row>
          <b-row>
            <div class="col-6">
              <img src="../assets/join2.png">
            </div>
            <div class="col-6">
              <div class="item1">
                <div>开放学习</div>
              </div>
            </div>
          </b-row>
          <b-row>
            <div class="col-6">
              <div class="item2">
                <div>合伙文化</div>
              </div>
            </div>
            <div class="col-6">
              <img src="../assets/join3.png">
            </div>
          </b-row>
          <b-row>
            <div class="col-6">
              <img src="../assets/join4.png">
            </div>
            <div class="col-6">
              <div class="item3">
                <div>风控合规</div>
              </div>
            </div>
          </b-row>
        </b-container>
      </section>
    </div>
    <Footer class="footer" />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Join',
  components: {
    Header,
    Footer
  }
}
</script>

<style scoped>
.banner {
  height: 550px;
}
.banner .background {
  width: 100%;
  height: 100%;
  color: #333333;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../assets/join1.jpg");
}
.banner .container {
  position: relative;
  top: 200px;
}
.banner .title {
  font-size: 24px;
  line-height: 1.8;
}
.banner a {
  padding: 8px 30px;
  color: #333333;
  background-color:rgba(255, 255, 255, 0.8);
  position: relative;
  top: 50px;
  border:solid 1px #333333;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  font-size: 18px;
}
.culture .col-6 {
  padding: 0;
}
.culture img {
  width: 100%;
}
.culture .item1 {
  padding: 20px 0 20px 20px;
  height: 100%;
}
.culture .item2 {
  padding: 20px 20px 20px 0;
  height: 100%;
}
.culture .item3 {
  padding: 20px 0 20px 20px;
  height: 100%;
}
.culture .item1 div {
  background-color: #0078d9;
  padding-top: 20px;
  padding-left: 20px;
  font-size: 22px;
  color: #ffffff;
  height: 100%;
}
.culture .item2 div {
  padding-top: 20px;
  padding-left: 20px;
  font-size: 22px;
  color: #ffffff;
  background-color: #18466e;
  height: 100%;
}
.culture .item3 div {
  background-color: #009ede;
  padding-top: 20px;
  padding-left: 20px;
  font-size: 22px;
  color: #ffffff;
  height: 100%;
}
.culture .text {
  width: 100%;
  text-align: center;
  font-size: 32px;
  color: #333333;
  padding-bottom: 50px;
}
.culture {
  padding: 80px 0;
}
</style>
